import { ChangeEvent, InputHTMLAttributes, Ref, forwardRef } from "react";
import styled, { css } from "styled-components";
import { Field, FieldProps } from "formik";
import { InputWrapperWithErrorMessage } from "./input_wrappers";
import { ColorsSystem } from "../colors";
import { SharedInputProps, defaultInputShapeCSS } from "./input_config";

type TextInputProps = InputHTMLAttributes<HTMLInputElement> & SharedInputProps;

export const TextInput = forwardRef(
  (
    { onValueChange, label, error, width, ...props }: TextInputProps,
    ref: Ref<HTMLInputElement> | null
  ) => {
    return (
      <Field name={props.name}>
        {({ field, form }: FieldProps) => {
          const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
            const { value } = event.currentTarget;
            if (!props.onChange && !onValueChange) {
              form.setFieldValue(field.name, value);
            } else {
              props.onChange?.(event);
              onValueChange?.(value);
            }
          };
          return (
            <InputWrapperWithErrorMessage
              error={error}
              label={label}
              width={width}
            >
              <TextInputContainer
                {...props}
                {...field}
                isValid={!error}
                ref={ref}
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleChange(event)
                }
              />
            </InputWrapperWithErrorMessage>
          );
        }}
      </Field>
    );
  }
);

export const commonInputCSS = css<{ isValid: boolean }>`
  ${defaultInputShapeCSS};
  padding: 4px 8px;
  font-size: 16px;
  background-color: transparent;
  line-height: 24px;
  color: ${ColorsSystem.grey};
  ::placeholder {
    font-size: initial;
    color: ${ColorsSystem.lightGrey};
  }
  ${({ isValid }) => {
    const errorColor = ColorsSystem.red;
    const regularColor = isValid ? ColorsSystem.powderBlueOne : errorColor;
    const activeColor = isValid ? ColorsSystem.powderBlueOne : errorColor;
    return css`
      border: 0.5px solid ${ColorsSystem.darkGrey};
      :hover {
        border: 0.5px solid ${ColorsSystem.powderBlueOne};
      }
      :focus,
      :active {
        border: 1px solid ${ColorsSystem.powderBlueOne};
        border-color: ${ColorsSystem.powderBlueOne};
      }
    `;
  }};
`;

export const TextInputContainer = styled.input`
  ${commonInputCSS};
`;
