import React, { useContext, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { useUserContext } from "../../contexts/user_context";
import {
  auth,
  createAuthUserWithEmailAndPassword,
  createAuthUserWithEmailAndPasswordByAdmin,
  createUserDocFromAuth,
} from "../../utils/firebase/firebase.utils";
import { BluePanelTitle, RegularText, SmallText } from "../ui/texts";

import { AddChildren } from "./add_child";
import { UserInfos } from "types/user.types";
import { HStack, VStack } from "components/ui/containers/stacks";
import { Panel } from "components/ui/cards";
import { FilledButton } from "components/ui/buttons";
import { TextInput } from "components/ui/inputs/text_input";
import { DatePickerInput } from "components/ui/inputs/date_picker_input";
import styled from "styled-components";
import { ColorsSystem } from "components/ui/colors";
import { OptionType, SelectInput } from "components/ui/inputs/select_input";
import { RadioButton } from "components/ui/inputs/radio_button";
import {
  SPACE_BETWEEN_ELEMENT_INNER_STACK,
  SPACE_BETWEEN_INPUT,
} from "components/ui/spacings";
import { TextAreaInput } from "components/ui/inputs/text_area_input";
import { ModalContext } from "components/ui/modal";

// tri => ajouter lors de l'extract une colonne vide "tri"
export type UserInfosAndPassword = Omit<UserInfos, "id" | "createdAt"> & {
  password: string;
  confirmPassword: string;
};

const initialValues: UserInfosAndPassword = {
  type: "",
  lastName: "",
  firstName: "",
  login: "",
  password: "",
  confirmPassword: "",
  email: `${Math.floor(Math.random() * 99999)}@testing.nowhere`,
  landLineNumber: "",
  phoneNumber: "",
  civilAdress: {
    numberAdress: "",
    adress: "",
    zipcode: "",
    city: "",
    country: "",
  },
  region: "",
  diploma: "",
  birthdate: "",
  grade: "",
  DATECEN: "",
  DATE1LCL: "",
  DATE2CL: "",
  brigade: "",
  army: "",
  originArmy: "",
  maritalSituation: {
    maritalStatus: "Célibataire",
    wifeName: "",
    childrenCount: 0,
  },
  children: [],
  permanentAdress: {
    numberAdress: "",
    adress: "",
    zipcode: "",
    city: "",
    country: "",
  },
  repReunion: "",
  promotion: "",
  cotisations: [],
  isDeceased: false,
  deceasedData: null,
  moreInfos:
    "Pas d'information reçue au moment d'imprimer le présent bulletin.",
};

const validationSchema = Yup.object().shape({
  type: Yup.number().required("Type obligatoire"),
  lastName: Yup.string().required(" Nom de famille obligatoire"),
  firstName: Yup.string().required(" Prénom obligatoire"),
  login: Yup.string().required(" Login obligatoire"),
  email: Yup.string().email("email invaide").required("Email obligatoire"),
  password: Yup.string().required("Mot de passe est obligatoire"),
  confirmPassword: Yup.string()
    .oneOf(
      [Yup.ref("password"), ""],
      "Les mots de passe doivent être identiques"
    )
    .required("Confirmation du mot de passe est obligatoire"),
  landLineNumber: Yup.number(),
  phoneNumber: Yup.number(),
  civilAdress: Yup.object().shape({
    numberAdress: Yup.number(), //.required("Numéro de rue obligatoire"),
    adress: Yup.string(), //.required("Nom de rue obligatoire"),
    zipcode: Yup.number(), //.required("Code postal obligatoire"),
    city: Yup.string(), //.required("Nom de la ville obligatoire"),
    country: Yup.string(), //.required("Nom du pays obligatoire"),
  }),
  region: Yup.string(),
  diploma: Yup.string(),
  birthdate: Yup.date(), //.required("Date de naissance obligatoire"),
  grade: Yup.string(),
  DATECEN: Yup.string(),
  DATE1LCL: Yup.string(),
  DATE2CL: Yup.string(),
  brigade: Yup.string(),
  army: Yup.string(),
  originArmy: Yup.string(),
  maritalSituation: Yup.object().shape({
    maritalStatus: Yup.string(), //.required("Situation familial obligatoire"),
    wifeName: Yup.string(),
    moreInfos: Yup.string(),
    childrenCount: Yup.number(), //.required("Nombre d'enfant obligatoire"),
  }),
  children: Yup.array().of(
    Yup.object().shape(
      {
        birthdate: Yup.string(),
        name: Yup.string(), // .required("Nom de l'épouse obligatoire"),
      },
      [["birthdate", "name"]]
    )
  ),
  permanentAdress: Yup.object().shape({
    numberAdress: Yup.number(), //.required("Numéro de rue obligatoire"),
    adress: Yup.string(), //.required("Nom de rue obligatoire"),
    zipcode: Yup.number(), //.required("Code postal obligatoire"),
    city: Yup.string(), //.required("Nom de la ville obligatoire"),
    country: Yup.string(), //.required("Nom du pays obligatoire"),
  }),
  repReunion: Yup.number(),
  promotion: Yup.string(),
  cotisations: Yup.array().of(
    Yup.object().shape(
      {
        date: Yup.date(),
        value: Yup.number(),
        paid: Yup.boolean(),
      },
      []
    )
  ),
  deceased: Yup.object().shape({
    state: Yup.boolean(),
    date: Yup.date(),
    place: Yup.string(),
    widowNumber: Yup.number(),
  }),
});

export const CreateMemberForm = () => {
  const { handleModal } = useContext(ModalContext);

  const handleSubmit = async (
    values: UserInfosAndPassword,
    resetForm: () => void
  ) => {
    const {
      lastName,
      firstName,
      login,
      password,
      confirmPassword,
      email,
      landLineNumber,
      phoneNumber,
      civilAdress,
      region,
      diploma,
      birthdate,
      grade,
      DATECEN,
      DATE1LCL,
      DATE2CL,
      brigade,
      army,
      originArmy,
      maritalSituation,
      children,
      permanentAdress,
      repReunion,
      promotion,
      cotisations,
      isDeceased,
      deceasedData,
    } = values;
    if (password !== confirmPassword) {
      alert("Attention! Vos mots de passe doivent être identiques");
      return;
    }

    if (password.length < 6) {
      alert("Le mot de passe doit contenir au moins 6 caractères");
      return;
    }

    try {
      const { user } = await createAuthUserWithEmailAndPasswordByAdmin(
        email,
        password
      );

      console.log("got user", user);

      await createUserDocFromAuth(user, {
        lastName,
        firstName,
        login,
        email,
        landLineNumber,
        phoneNumber,
        civilAdress,
        region,
        diploma,
        birthdate,
        grade,
        DATECEN,
        DATE1LCL,
        DATE2CL,
        brigade,
        army,
        originArmy,
        maritalSituation,
        children,
        permanentAdress,
        repReunion,
        promotion,
        cotisations,
        isDeceased,
        deceasedData,
      });
      alert("Utilisateur créé avec succès!");
      handleModal();
      resetForm();
    } catch (error: any) {
      if (error.code === "auth/email-already-in-use")
        alert("email déjà utilisé");
      console.log("error", error);
    }
  };

  const maritaStatusOptions: OptionType[] = [
    { value: "Célibataire", label: "Célibataire" },
    { value: "Marié", label: "Marié" },
    { value: "Divorcé", label: "Divorcé" },
    { value: "Veuf", label: "Veuf" },
  ];

  return (
    <VStack>
      <BluePanelTitle>CREATION MEMBRE PROMOTION</BluePanelTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}
        validationSchema={validationSchema}
      >
        {({ dirty, values, errors, setFieldValue, isValid, isSubmitting }) => {
          return (
            <Form>
              <VStack gap={20}>
                <VStack>
                  {Object.values(errors)
                    .flatMap((x) =>
                      typeof x === "string" ? x : Object.values(x)
                    )
                    .map((e, i) => (
                      <SmallText color="red" key={`errnum${i}`}>
                        {(e || "").toString()}
                      </SmallText>
                    ))}
                </VStack>
                <HStack gap={20}>
                  <InfosWrapper>
                    <InfosTitle>Infos de base</InfosTitle>
                    <VStack>
                      <TextInput
                        id={"type"}
                        name={"type"}
                        type="text"
                        label={"Type d'utilisateur"}
                      />
                      <SmallText>type 0 : membre promo</SmallText>
                      <SmallText>type 1 : membre vorace</SmallText>
                      <SmallText>type 2 : membre secrétaire</SmallText>
                    </VStack>
                    <TextInput
                      id={"firstName"}
                      name={"firstName"}
                      type="text"
                      label={"Prénom"}
                    />
                    <TextInput
                      id={"lastName"}
                      name={"lastName"}
                      type="text"
                      label={"Nom"}
                    />
                    <TextInput
                      label="Date de naissance"
                      type="text"
                      name={"birthdate"}
                      id={"birthdate"}
                      placeholder="JJ/MM/AAAA"
                    />
                    <TextInput
                      label="Login"
                      required
                      type="login"
                      id="login"
                      name="login"
                    />
                    <VStack>
                      <TextInput
                        label="Mot de passe"
                        required
                        type="password"
                        id="password"
                        name="password"
                      />
                      <SmallText>au moins 6 caractères</SmallText>
                    </VStack>
                    <TextInput
                      label="Confirmez le mot de passe"
                      required
                      type="password"
                      id="confirmPassword"
                      name="confirmPassword"
                    />
                  </InfosWrapper>
                  <InfosWrapper>
                    <InfosTitle>Infos de contacts</InfosTitle>
                    <TextInput
                      id={"phoneNumber"}
                      name={"phoneNumber"}
                      type="text"
                      label={"Numéro portable"}
                    />
                    <TextInput
                      id={"landLineNumber"}
                      name={"landLineNumber"}
                      type="text"
                      label={"Numéro ligne fixe"}
                    />
                    <TextInput
                      label="Email"
                      required
                      type="email"
                      id="email"
                      name="email"
                    />
                    <InfosTitle>Infos supplémentaires</InfosTitle>
                    <RadioButton
                      text="Décédé"
                      onClick={() => {
                        setFieldValue("isDeceased", !values.isDeceased);
                      }}
                      checked={values.isDeceased}
                    />
                    {values.isDeceased && (
                      <VStack gap={SPACE_BETWEEN_INPUT}>
                        <TextInput
                          id={"deceasedData.date"}
                          name={"deceasedData.date"}
                          type="text"
                          label={"Date de décès"}
                          placeholder="JJ/MM/AAAA"
                        />
                        <TextInput
                          id={"deceasedData.place"}
                          name={"deceasedData.place"}
                          type="text"
                          label={"Lieu de décès"}
                        />
                        <TextInput
                          id={"deceasedData.widowNumber"}
                          name={"deceasedData.widowNumber"}
                          type="text"
                          label={"Numéro veuve"}
                        />
                      </VStack>
                    )}
                  </InfosWrapper>
                  <InfosWrapper>
                    <InfosTitle>Infos Famille</InfosTitle>
                    <SelectInput
                      label="Statut marital"
                      options={maritaStatusOptions}
                      onChange={(option) => {
                        setFieldValue(
                          "maritalSituation.maritalStatus",
                          option.value
                        );
                      }}
                    ></SelectInput>
                    <TextInput
                      id={"maritalSituation.wifeName"}
                      name={"maritalSituation.wifeName"}
                      type="text"
                      label={"Nom de l'épouse"}
                    />
                    <TextInput
                      id={"childrenCount"}
                      name={"childrenCount"}
                      type="number"
                      label={"Nombre d'enfant"}
                    />
                    <VStack gap={10}>
                      <RegularText>Enfants</RegularText>
                      <AddChildren
                        childrenValues={values.children}
                        name={"children"}
                        id={"children"}
                      />
                    </VStack>
                  </InfosWrapper>
                </HStack>

                <InfosWrapper>
                  <InfosTitle> Infos Adresses</InfosTitle>
                  <HStack gap={60}>
                    <VStack>
                      <RegularText>Adresse Civile</RegularText>
                      <TextInput
                        id={"civilAdress.numberAdress"}
                        name={"civilAdress.numberAdress"}
                        type="text"
                        label={"Numéro de rue"}
                      />
                      <TextInput
                        id={"civilAdress.adress"}
                        name={"civilAdress.adress"}
                        type="text"
                        label={"Rue"}
                      />
                      <TextInput
                        id={"civilAdress.zipcode"}
                        name={"civilAdress.zipcode"}
                        type="text"
                        label={"Code postal"}
                      />
                      <TextInput
                        id={"civilAdress.city"}
                        name={"civilAdress.city"}
                        type="text"
                        label={"Ville"}
                      />
                      <TextInput
                        id={"civilAdress.country"}
                        name={"civilAdress.country"}
                        type="text"
                        label={"Pays"}
                      />
                      {/* <TextInput
                    id={"civilAdress.region"}
                    name={"civilAdress.region"}
                    type="text"
                    label={"Région"}
                  /> */}
                    </VStack>
                    <VStack>
                      <RegularText>Adresse Permanente</RegularText>
                      <TextInput
                        id={"permanentAdress.numberAdress"}
                        name={"permanentAdress.numberAdress"}
                        type="text"
                        label={"Numéro de rue"}
                      />
                      <TextInput
                        id={"permanentAdress.adress"}
                        name={"permanentAdress.adress"}
                        type="text"
                        label={"Rue"}
                      />
                      <TextInput
                        id={"permanentAdress.zipcode"}
                        name={"permanentAdress.zipcode"}
                        type="text"
                        label={"Code postal"}
                      />
                      <TextInput
                        id={"permanentAdress.city"}
                        name={"permanentAdress.city"}
                        type="text"
                        label={"Ville"}
                      />
                      <TextInput
                        id={"permanentAdress.country"}
                        name={"permanentAdress.country"}
                        type="text"
                        label={"Pays"}
                      />
                    </VStack>
                  </HStack>
                </InfosWrapper>

                <HStack gap={20}>
                  <InfosWrapper>
                    <InfosTitle>Infos carrière</InfosTitle>
                    <HStack gap={60}>
                      <VStack>
                        <TextInput
                          id={"promotion"}
                          name={"promotion"}
                          type="text"
                          label={"Promotion"}
                        />
                        <TextInput
                          id={"originArmy"}
                          name={"originArmy"}
                          type="text"
                          label={"Armée d'origine"}
                        />
                        <TextInput
                          id={"army"}
                          name={"army"}
                          type="text"
                          label={"Armée"}
                        />
                        <TextInput
                          id={"brigade"}
                          name={"brigade"}
                          type="text"
                          label={"Brigade"}
                        />
                      </VStack>
                      <VStack>
                        <TextInput
                          id={"DATECEN"}
                          name={"DATECEN"}
                          type="text"
                          label={"DATECEN"}
                        />
                        <TextInput
                          id={"DATE1LCL"}
                          name={"DATE1LCL"}
                          type="text"
                          label={"DATE1LCL"}
                        />
                        <TextInput
                          id={"DATE2CL"}
                          name={"DATE2CL"}
                          type="text"
                          label={"DATE2CL"}
                        />
                        <TextInput
                          id={"diploma"}
                          name={"diploma"}
                          type="text"
                          label={"Diplôme"}
                        />
                        <TextInput
                          id={"grade"}
                          name={"grade"}
                          type="text"
                          label={"Grade"}
                        />
                      </VStack>
                    </HStack>
                  </InfosWrapper>

                  <InfosWrapper>
                    <VStack gap={20} style={{ minWidth: 400 }}>
                      <InfosTitle>Infos supplémentaires</InfosTitle>
                      <TextAreaInput
                        id={"infos supplémentaire"}
                        name={"infos supplémentaire"}
                        width="100%"
                        numberOfRows={10}
                      />
                    </VStack>
                  </InfosWrapper>
                </HStack>
              </VStack>

              <VStack
                style={{
                  position: "sticky",
                  bottom: 0,
                  backgroundColor: "rgba(255, 255, 255, 0.6)",
                  paddingTop: 20,
                }}
                alignItems="center"
              >
                <FilledButton
                  title="Créer l'utilisateur"
                  buttonSize="medium"
                  type={"submit"}
                  disabled={!isValid}
                />
              </VStack>
            </Form>
          );
        }}
      </Formik>
    </VStack>
  );
};

const InfosWrapper = styled(VStack)`
  padding: 10px 40px 20px 20px;
  gap: 10px;
  border: 2px solid ${ColorsSystem.powderBlueOne};
  border-radius: 5px;
`;

const InfosTitle = styled(RegularText)`
  color: ${ColorsSystem.primary_active};
`;
