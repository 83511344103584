import { CreateMemberForm } from "components/sign_up/sign_up_form";
import { VStack } from "components/ui/containers/stacks";

export const AddUser = () => {
  return (
    <VStack>
      <CreateMemberForm />
    </VStack>
  );
};
