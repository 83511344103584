import { Panel } from "components/ui/cards";
import { ColorsSystem } from "components/ui/colors";
import { H4 } from "components/ui/headings";
import { HStack, VStack } from "components/ui/containers/stacks";
import { RegularText, SmallText } from "components/ui/texts";
import { useContext, useState } from "react";
import styled from "styled-components";
import { Option } from "types/global_types";
import { Cotisation, LegacyCotisation, UserInfos } from "types/user.types";
import {
  getCotisationFromLegacyCotisation,
  isStringNullOrEmpty,
} from "helpers/helpers";
import { getFourLastLetters } from "components/profile/profile_card";
import { FilledButton, OutlineButton } from "components/ui/buttons";
import { AddCotisationsToMemberForm } from "routes/admin_space/add_cotisation";
import { ModalContext } from "components/ui/modal";
import { SearchInput } from "components/ui/inputs/search_input";
import { UpdateUserCotisationsForm } from "routes/admin_space/update_cotisations";
import { RecuFiscalExtractModal } from "routes/admin_space/recu_fiscal_extract";
import { AddUser } from "routes/admin_space/add_user";

type MembersToAdminProps = {
  members: UserInfos[];
};
export const MembersToAdmin = ({ members }: MembersToAdminProps) => {
  const { handleModal } = useContext(ModalContext);
  const [valueSearch, setValueSearch] = useState("");
  const [updatedMembers, setUpdatedMembers] = useState(members);

  const handleCreateUser = (): void => {
    handleModal(<AddUser />);
  };

  const updateUser = (updatedUser: Option<UserInfos>) => {
    if (!updatedUser) return;
    const updatedList = updatedMembers.map((member) =>
      member.id === updatedUser.id ? updatedUser : member
    );
    setUpdatedMembers(updatedList);
  };

  const sortedMembers =
    updatedMembers &&
    updatedMembers.sort((a, b) => {
      const lastNameA = a.lastName.toLowerCase();
      const lastNameB = b.lastName.toLowerCase();

      if (lastNameA < lastNameB) {
        return -1;
      }
      if (lastNameA > lastNameB) {
        return 1;
      }
      return 0;
    });

  const handleAddCotisationToMember = (user: Option<UserInfos>): void => {
    user &&
      handleModal(
        <AddCotisationsToMemberForm updateUser={updateUser} user={user} />
      );
  };

  const matchesSearch = (name: string): boolean => {
    if (!name) return false;
    return name.toLowerCase().includes(valueSearch.toLowerCase());
  };

  const filteredMembers = sortedMembers.filter(
    (member) =>
      matchesSearch(member.firstName) || matchesSearch(member.lastName)
  );

  const handleUpdateUserCotisations = (user: Option<UserInfos>): void => {
    user &&
      handleModal(
        <UpdateUserCotisationsForm updateUser={updateUser} user={user} />
      );
  };
  const handleOpenRecuFiscalModal = (
    cotisation: Cotisation,
    user: UserInfos
  ) => {
    console.log("cotisation in modal", cotisation);

    user &&
      handleModal(
        <RecuFiscalExtractModal member={user} cotisation={cotisation} />
      );
  };

  return (
    <VStack alignItems="center" gap={40}>
      <HStack
        style={{ paddingLeft: 40, paddingRight: 40 }}
        fullWidth
        justifyContent="space-between"
      >
        <FilledButton
          title="Créer un membre"
          buttonSize="small"
          onClick={handleCreateUser}
        />
        <SearchInput
          placeholder={"chercher un membre"}
          onChange={(e) => setValueSearch(e.target.value)}
          value={valueSearch}
        />
      </HStack>
      <HStack justifyContent="center" wrap="wrap" gap={24}>
        {filteredMembers.map((member) => {
          return (
            <Panel key={member.id}>
              <VStack style={{ width: 200 }} alignItems="center" gap={10}>
                <RegularText>
                  {member.lastName + " " + member.firstName}
                </RegularText>
                <SmallText>
                  {member.email === "" ? "pas d'email associé" : member.email}
                </SmallText>
                <HStack>
                  <CotisationsWrapper
                    cotisations={member.cotisations}
                    handleRecuFiscalCreation={(cotisation) =>
                      handleOpenRecuFiscalModal(cotisation, member)
                    }
                  />
                </HStack>
                <OutlineButton
                  buttonSize="small"
                  title="Ajouter une cotisation"
                  onClick={() => handleAddCotisationToMember(member)}
                />
                <OutlineButton
                  buttonSize="small"
                  title="Modifier les cotisations"
                  onClick={() => handleUpdateUserCotisations(member)}
                />
              </VStack>
            </Panel>
          );
        })}
      </HStack>
    </VStack>
  );
};

export const cotisationsSortedByDate = (cotisations: Cotisation[]) => {
  return cotisations.sort((a, b) => {
    if (!a.date) return 1;
    if (!b.date) return -1;

    let dateA = new Date(a.date.split("/").reverse().join("/")).getTime();
    let dateB = new Date(b.date.split("/").reverse().join("/")).getTime();
    return dateB - dateA;
  });
};

type CotisationProps = {
  cotisations: Cotisation[];
  handleRecuFiscalCreation: (cotisation: Cotisation) => void;
};

const CotisationsWrapper = ({
  cotisations,
  handleRecuFiscalCreation,
}: CotisationProps) => {
  const cotisationsUpdated = cotisations.map((cotisation) => {
    return getCotisationFromLegacyCotisation(cotisation);
  });

  return (
    <HorizontalCotisationsWrapper gap={20}>
      {cotisationsSortedByDate(cotisationsUpdated).map((cotisation, index) => {
        // console.log("cotisation", cotisation);

        return (
          <CotisationContainer key={index} index={index}>
            <VStack gap={5}>
              <H4 color={ColorsSystem.powderBlueOne}>
                {cotisation.date &&
                  cotisation.date !== "" &&
                  getFourLastLetters(cotisation.date)}
              </H4>
              {/* <RegularText>{cotisation.date}</RegularText> */}
              <RegularText>
                {cotisation.value < 1 || cotisation.value === undefined
                  ? 0
                  : cotisation.value}
                {"€"}
              </RegularText>
              <SmallText>
                {"( "}
                {isStringNullOrEmpty(cotisation.valueInString)
                  ? "zéro"
                  : cotisation.valueInString}
                {" )"}
              </SmallText>

              <Paid paid={cotisation.paid}>payé</Paid>
            </VStack>
            <FilledButton
              buttonSize="extraSmall"
              title="Recu fiscal"
              onClick={() => handleRecuFiscalCreation(cotisation)}
            />
          </CotisationContainer>
        );
      })}
    </HorizontalCotisationsWrapper>
  );
};

const Paid = styled(RegularText)<{ paid: boolean }>`
  color: ${(props) =>
    props.paid ? ColorsSystem.green : ColorsSystem.mediumGrey};
  text-decoration: ${(props) => (!props.paid ? "line-through" : "none")};
  text-decoration-color: ${ColorsSystem.mediumGrey};
`;

const HorizontalCotisationsWrapper = styled(HStack)`
  overflow: auto;
  width: 200px;
  padding: 10px;
  margin-bottom: 22px;
  margin-top: 20px;
`;

const CotisationContainer = styled(VStack)<{ index: number }>`
  background: ${(props) =>
    props.index % 2 === 0 ? ColorsSystem.beige : "transparent"};
  padding: 10px;
  gap: 15px;
  border-radius: 8px;
  min-height: 150px;
  justify-content: space-between;
`;
